export let isTourInterest = false;
export let isOrderBrochure = false;
export let isLearnInterest = false;
export let isWhatsAppChat = false;
export let isSchoolClosed = false;
export let isOrlandoPromo = false;
export let isTampaPromo = false;
export let isPartnersPage = false;
export let isAmericanHighSchoolInterest = false;
export let isAmericanHighSchoolEnrollment = false;
export let visitedBefore = localStorage.getItem("isOldVisitor") !== null;
export let email = '';
export let phone = '';
import * as Translator from './translator';
const isTourPage = document.body.getAttribute('data-is-tour-page') === 'true';
const isCashPage = document.body.getAttribute('data-is-cash-page') === 'true';
const isSchoolPage = document.body.getAttribute('data-is-school-page') === 'true';


export function checkOpenModalOrOldVisitor() {
  var openModals = document.querySelectorAll('.modal.show');
  return openModals.length > 0 || localStorage.getItem("isOldVisitor") !== null;
}

export function setEmail(value) {
  email = value;
}

export function setPhone(value) {
  phone = value;
}

export function isFirstTimeVisitor() {
  return !localStorage.getItem('isOldVisitor') && !hasMatchingToursQrUTMParameters();
}

export async function customizeModalForFirstTimeVisitor() {
    const modalTitle = document.getElementById("zoniModalLabel");
    //const modalBody = document.querySelector("#zoniModal .modal-body");
    const modalP = document.getElementById("zoniModalP");
    if (!modalP || !modalTitle) {
        return;
    }
    modalTitle.textContent = await Translator.translateText("Welcome to Zoni!");
    modalP.textContent = await Translator.translateText("Hello! We’d love to get to know you better. Please take a moment to fill out the contact form below so we can assist you more effectively.");
    modalP.style.display = 'block';
}

export async function setModalToDefault() {
  // Reset the modal title
  const modalTitle = document.getElementById("zoniModalLabel");
  const modalP = document.getElementById("zoniModalP");
  const modalSmall = document.getElementById("zoniModalSmall");
  const nameLabel = document.getElementById("zoniModalNameLabel");
  const emailLabel = document.getElementById("zoniModalEmailLabel");
  const phoneLabel = document.getElementById("zoniModalPhoneLabel");
  const studentName = document.getElementById("studentName");
  const form = document.getElementById('zoni-form');
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  const textarea = document.getElementById("message");

  if (!modalTitle || !modalP || !form || !messageLabel || !textarea) {
    return;
  }

  modalTitle.textContent = await Translator.translateText("Contact Us");
  nameLabel.textContent = await Translator.translateText("Name:");
  emailLabel.textContent = await Translator.translateText("Email:");
  phoneLabel.textContent = await Translator.translateText("Phone:");
  studentName.style.display = 'none';
  // Reset the modal message
  modalP.textContent = '';
  modalP.style.display = 'none';
  modalSmall.style.display = 'none';

  // Reset the form values
  form.reset();

  // Reset message Label form values
  messageLabel.textContent = await Translator.translateText("Let us know what you need:");
  

  // Hide email and phone error messages
  document.getElementById('email-error').style.display = 'none';
  document.getElementById('phone-error').style.display = 'none';
  document.getElementById('guardian-info').style.display = 'none';
  // Hide the hidden fields specific for planning your own trip
  document.getElementById("referrer-info-input").style.display = 'none';
  document.getElementById('school_or_organization').parentNode.style.display = 'none';
  document.getElementById('destination').parentNode.style.display = 'none';
  document.getElementById('departure_city').parentNode.style.display = 'none';
  document.getElementById('person_type').parentNode.style.display = 'none';
  
  // Reset text area
  messageLabel.style.display = 'block';
  textarea.style.display = 'block';
  textarea.setAttribute('required', 'required');
  $("#referrer_name").removeAttr("required");
    $("#referrer_email").removeAttr("required");
    $("#referrer_birth_date").removeAttr("required");
    $("#student_name").removeAttr("required");
  // Enable the submit button (if it was disabled)
  form.querySelector('button[type="submit"]').disabled = false;

  // Reset interests
  isTourInterest = false;
  isOrderBrochure = false;
  isLearnInterest = false;
  isWhatsAppChat = false;
  isPartnersPage = false;
  isAmericanHighSchoolInterest = false;
  isAmericanHighSchoolEnrollment = false;

  if (isTourPage) {
    customizeModalToursPage();
  }
  else if (isCashPage) {
    customizeModalCashPage();
  }
  else if (isSchoolPage) {
    customizeModalForSchool();
  }
}

export async function customizeModalForPlanYourOwnTrip() {
  const modalTitle = document.getElementById("zoniModalLabel");
  modalTitle.textContent = await Translator.translateText("Plan Your Own Trip with Zoni!");

  // Show the hidden fields specific for planning your own trip
  document.getElementById('school_or_organization').parentNode.style.display = 'block';
  document.getElementById('destination').parentNode.style.display = 'block';
  document.getElementById('departure_city').parentNode.style.display = 'block';
  document.getElementById('person_type').parentNode.style.display = 'block';

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Looking to customize your own trip? Tell us more and we'll assist you!");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Tell us anything else to plan your own trip:");

  isTourInterest = true;
  isWhatsAppChat = false;
}


/**
 * Maps the selected person type to the corresponding checkbox values.
 *
 * @export
 * @param {('tour_teacher' | 'parent' | 'participant' | 'tour_leader')} personType - The type of the person selected.
 * @returns {{ tour_teacher: number, parent1: number, participant: number, tour_leader: number }} An object containing the mapped checkbox values.
 * @example
 * 
 * mapPersonTypeToCheckboxes("parent");
 * // Returns: { parent1: 1, participant: 0, tour_leader: 0 }
 */
export function mapPersonTypeToCheckboxes(personType) {
  let mappedCheckbox = {
    board_member: 0,
    board_president: 0,
    chief_of_education: 0,
    parent1: 0,
    participant: 0,
    principal: 0,
    superintendent: 0,
    tour_leader: 0,
    tour_teacher: 0,
  }
  if(isTourInterest || isOrderBrochure){
    mappedCheckbox =
    {
      board_member: personType === "board_member" ? 1 : 0,
      board_president: personType === "board_president" ? 1 : 0,
      chief_of_education: personType === "chief_of_education" ? 1 : 0,
      parent1: personType === "parent" ? 1 : 0,
      participant: personType === "participant" ? 1 : 0,
      principal: personType === "principal" ? 1 : 0,
      superintendent: personType === "superintendent" ? 1 : 0,
      tour_leader: personType === "tour_leader" ? 1 : 0,
      tour_teacher: personType === "tour_teacher" ? 1 : 0,
    }
  }
  return mappedCheckbox;
}


export function hasMatchingToursQrUTMParameters() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  return params.get('utm_source') === 'letter' &&
         params.get('utm_medium') === 'QR' &&
         params.get('utm_campaign') === 'education';
}

export function hasMatchingQrRollupUTMParameters() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  return params.get('utm_source') === 'rollup' &&
         params.get('utm_medium') === 'QR';
}


export function hasMatchingZoniLanguageCentersQrUTMParameters() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if (params.get('utm_source') === 'flyer' &&
      params.get('utm_medium') === 'QR') {
      return params.get('utm_campaign')
  }
  return false;
}

export function hasMatchingQr() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if ( params.get('utm_medium') === 'QR') {
      return { utm_campaign : params.get('utm_campaign'), utm_source : params.get('utm_source') }
  }
  return false;
}

export function hasMatchingQrAndIsScanSource() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if ( params.get('utm_medium') === 'QR' && params.get('utm_source') === 'scan') {
      return true;
  }
  return false;
}

export function hasMatchingQRisSchoolClosed() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if ( params.get('utm_medium') === 'QR' && params.get('utm_source') === 'school_closed') {
      return true;
  }
  return false;
}

export function hasMatchingQRisOrlandoPromo() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if ( params.get('utm_medium') === 'QR' && params.get('utm_source') === 'orlando_promo') {
      return true;
  }
  return false;
}

export function hasMatchingQRisTampaPromo() {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  if ( params.get('utm_medium') === 'QR' && params.get('utm_source') === 'tampa_promo') {
      return true;
  }
  return false;
}

export function checkOpenModal() {
  var openModals = document.querySelectorAll('.modal.show');
  return openModals.length > 0;
}


export async function customizeModalForToursQrUTM() {
  const modalTitle = document.getElementById("zoniModalLabel");
  modalTitle.textContent = await Translator.translateText("Welcome to Zoni NJEA Conference 2023!");

  // Show the hidden fields specific for planning your own trip
  document.getElementById('school_or_organization').parentNode.style.display = 'block';
  //document.getElementById('destination').parentNode.style.display = 'block';
  //document.getElementById('departure_city').parentNode.style.display = 'block';
  document.getElementById('person_type').parentNode.style.display = 'block';

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Visit us at booth 1135 at NJEA Conference at the Atlantic City Convention Center in Atlantic City, NJ and fill out this form to enter our drawing for a $150 visa gift card and exclusive gifts and surprises! 🌍🎁");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Tell us more about yourself or how we can assist you further!");

  isTourInterest = true;
  isWhatsAppChat = false;
}

export async function customizeModalForOrderBrochure() {
  const modalTitle = document.getElementById("zoniModalLabel");
  modalTitle.textContent = await Translator.translateText("Order Zoni Tours Brochure!");

  // Show the hidden fields specific for planning your own trip
  document.getElementById('school_or_organization').parentNode.style.display = 'block';
  //document.getElementById('destination').parentNode.style.display = 'block';
  //document.getElementById('departure_city').parentNode.style.display = 'block';
  document.getElementById('person_type').parentNode.style.display = 'block';

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("By filling out this form, you will get our Zoni Tours brochure, including all our educational tours and field trips!");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Tell us more about yourself or how we can assist you further!");

  isTourInterest = true;
  isOrderBrochure = true;
  isWhatsAppChat = false;
}
export async function customizeModalForPartners() {
  const modalTitle = document.getElementById("zoniModalLabel");
  modalTitle.textContent = await Translator.translateText("Contact Zoni American High School!");

  // Show the hidden fields specific for planning your own trip
  document.getElementById('school_or_organization').parentNode.style.display = 'block';
  //document.getElementById('destination').parentNode.style.display = 'block';
  //document.getElementById('departure_city').parentNode.style.display = 'block';
  document.getElementById('person_type').parentNode.style.display = 'block';

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Message");

  isPartnersPage = true;
  isWhatsAppChat = false;
}

export async function customizeModalToursPage() {
  const modalTitle = document.getElementById("zoniModalLabel");
  modalTitle.textContent = await Translator.translateText("Contact Zoni Tours!");

  // Show the hidden fields specific for planning your own trip
  document.getElementById('school_or_organization').parentNode.style.display = 'block';
  //document.getElementById('destination').parentNode.style.display = 'block';
  //document.getElementById('departure_city').parentNode.style.display = 'block';
  document.getElementById('person_type').parentNode.style.display = 'block';

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  //modalP.textContent = await Translator.translateText("By filling out this form, you will get our 2023 - 2024 school brochure, including all our Tours and educational Travel Programs!");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Tell us more about yourself or how we can assist you further!");

  isTourInterest = true;
  isWhatsAppChat = false;
}

export async function customizeModalCashPage() {
  const modalTitle = document.getElementById("zoniModalLabel");
  const nameLabel = document.getElementById("zoniModalNameLabel");
 
  modalTitle.textContent = await Translator.translateText("Claim your discount!");
 
  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Unlock a world of endless possibilities for your English language journey with Zoni. By filling out this form, you will get a $25 discount after completing your enrollment at Zoni Orlando School or Zoni Tampa School!");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Tell us more about yourself!");

  isLearnInterest = true;
  isWhatsAppChat = false;
}


export async function customizeModalSchoolCloseWhatsApp() {
  const modalTitle = document.getElementById("zoniModalLabel");
  const nameLabel = document.getElementById("zoniModalNameLabel");
  modalTitle.textContent = await Translator.translateText("Start a WhatsApp Live Chat Conversation!");

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Please provide us with the information below to start a WhatsApp live chat conversation:");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Message:");

  isSchoolClosed = true;
}


export async function customizeModalOrlandoPromoWhatsApp() {
  const modalTitle = document.getElementById("zoniModalLabel");
  const nameLabel = document.getElementById("zoniModalNameLabel");
  modalTitle.textContent = await Translator.translateText("Start a WhatsApp Live Chat Conversation!");

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Please provide us with the information below to start a WhatsApp live chat conversation:");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Message:");

  isOrlandoPromo = true;
}

export async function customizeModalTampaPromoWhatsApp() {
  const modalTitle = document.getElementById("zoniModalLabel");
  const nameLabel = document.getElementById("zoniModalNameLabel");
  modalTitle.textContent = await Translator.translateText("Start a WhatsApp Live Chat Conversation!");

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Please provide us with the information below to start a WhatsApp live chat conversation:");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Message:");

  isTampaPromo = true;
}

export async function customizeModalWhatsApp() {
  const modalTitle = document.getElementById("zoniModalLabel");
  const nameLabel = document.getElementById("zoniModalNameLabel");
  modalTitle.textContent = await Translator.translateText("Start a WhatsApp Live Chat Conversation!");

  // Additional customization for the modal message
  const modalP = document.getElementById("zoniModalP");
  modalP.textContent = await Translator.translateText("Please provide us with the information below to start a WhatsApp live chat conversation:");
  modalP.style.display = 'block';
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  messageLabel.textContent = await Translator.translateText("Message:");

  isWhatsAppChat = true;
}

export async function customizeModalForQrRollupVisitor() {
  const modalTitle = document.getElementById("zoniModalLabel");
  //const modalBody = document.querySelector("#zoniModal .modal-body");
  const modalP = document.getElementById("zoniModalP");
  modalTitle.textContent = await Translator.translateText("Welcome to Zoni!");
  modalP.textContent = await Translator.translateText("Hello there! We'd love to get to know you better. Please fill out this form! 🌍🎁");
  modalP.style.display = 'block';
}

export async function customizeSchoolEnrollment() {
  await setModalToDefault();
  const modalTitle = document.getElementById("zoniModalLabel");
  //const modalBody = document.querySelector("#zoniModal .modal-body");
  const modalP = document.getElementById("zoniModalP");
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  const textarea = document.getElementById("message");
  messageLabel.style.display = 'none';
  textarea.style.display = 'none';
  textarea.removeAttribute('required');
  modalTitle.textContent = await Translator.translateText("Welcome to Zoni American High School!");
  modalP.textContent = await Translator.translateText("To get started, simply provide the information below to create your account and begin the enrollment process!");
  modalP.style.display = 'block';

  isAmericanHighSchoolInterest = true;
  isAmericanHighSchoolEnrollment = true;
}

export async function customizeModalForSchool() {
  const modalTitle = document.getElementById("zoniModalLabel");
  //const modalBody = document.querySelector("#zoniModal .modal-body");
  const modalP = document.getElementById("zoniModalP");
  const messageLabel = document.getElementById("zoniModalMessageLabel");
  const textarea = document.getElementById("message");
  //messageLabel.style.display = 'none';
  //textarea.style.display = 'none';
  //textarea.removeAttribute('required');
  modalTitle.textContent = await Translator.translateText("Welcome to Zoni American High School!");
  modalP.textContent = await Translator.translateText("To get started, simply provide the information below");
  modalP.style.display = 'block';

  isAmericanHighSchoolInterest = true;
}
