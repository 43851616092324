import * as Translator from './translator';

const BASE_URL = "https://www.zoni.edu/zoni-store-files/uploads/";
const PRODUCT_BASE_URL = "https://www.zoni.edu/store/product/";
const DEFAULT_IMAGE_URL = '/assets/zoni_edu/assets/img/store/default_product.webp';

/**
 * Selects the preferred image URL for a product.
 * Prioritizes files with "categ": "P". If none found, selects the first available image.
 * @param {Array} files - Array of file objects for the product.
 * @returns {string} - The full URL to the selected image.
 */
function selectPreferredImage(files) {
    if (!files || files.length === 0) {
        return DEFAULT_IMAGE_URL;
    }

    // Try to find the first file with categ "P"
    const preferredFile = files.find(file => file.categ === "P");
    if (preferredFile) {
        return getFullImageUrl(preferredFile);
    }

    // If no "P" categ file, fallback to the first available image file
    const fallbackFile = files.find(file => file.categ === "S" || file.categ === "P");
    if (fallbackFile) {
        return getFullImageUrl(fallbackFile);
    }

    // If no image files found, return the default image
    return DEFAULT_IMAGE_URL;
}

/**
 * Builds the full image URL from a file object.
 * @param {Object} fileObj - The file object containing file details.
 * @returns {string} - The full URL to the image.
 */
function getFullImageUrl(fileObj) {
    if (fileObj.absoluteUrl) {
        return fileObj.absoluteUrl;
    }
    // Ensure that subdir does not start with a slash
    let subdir = fileObj.subdir.startsWith('/') ? fileObj.subdir.substring(1) : fileObj.subdir;
    return `${BASE_URL}${subdir}/${fileObj.fileName}`;
}

/**
 * Escapes HTML special characters to prevent XSS attacks.
 * @param {string} text - The text to escape.
 * @returns {string} - The escaped text.
 */
function escapeHtml(text) {
    if (!text) return '';
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };
    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

/**
 * Loads products from the API for a given page.
 * @param {number} page - The page number to load.
 */
export function loadProducts(page = 1) {
    // Check if the html element exists
    if ($('#products-container').length === 0) {
        return;
    }
    $.ajax({
        method: 'GET',
        url: '/api/method/zoni_edu.zoni_edu.integrations.zoni_store.get_store_products',
        data: {
            page: page,
            page_size: 3  // Number of products to show per page
        },
        success: function(response) {
            if (response.message) {
                displayProducts(response.message.products);
                updatePagination(
                    response.message.total_pages,
                    response.message.current_page
                );
            }
        },
        error: function(xhr, status, error) {
            console.error("Error loading products:", error);
            $('#products-container').html('<p>An error occurred while loading products.</p>');
        }
    });
}

/**
 * Displays the products in the DOM.
 * @param {Array} products - Array of product objects to display.
 */
async function displayProducts(products) {
    let container = $('#products-container');
    let viewDetails = await Translator.translateText("View Details");
    container.empty();

    if (!products || products.length === 0) {
        container.html('<p>No products found.</p>');
        return;
    }

    let row = $('<div class="row justify-content-center"></div>');
    products.forEach(function(product) {
        // Select the preferred image
        let imageUrl = selectPreferredImage(product.files);

        // Build the product card
        let productCard = `
            <div class="col-12 col-sm-6 col-md-4 mb-4">
                <div class="card h-100 product-card">
                    <img src="${imageUrl}" class="card-img-top" alt="${escapeHtml(product.titleMeta || '')}">
                    <div class="card-body">
                        <h5 class="card-title">${escapeHtml(product.titleMeta || '')}</h5>
                        <div class="d-flex justify-content-center">
                            <p class="card-text">$${escapeHtml(product.price || '')}</p>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-center">
                            <a href="${PRODUCT_BASE_URL}${product.productId}" class="btn btn-primary btn-sm">${viewDetails}</a>
                        </div>
                    </div>
                </div>
            </div>
        `;
        row.append(productCard);
    });

    container.append(row);
}

/**
 * Updates the pagination controls based on total pages and current page.
 * @param {number} totalPages - Total number of pages.
 * @param {number} currentPage - The current active page.
 */
function updatePagination(totalPages, currentPage) {
    let pagination = $('#pagination');
    pagination.empty();

    if (totalPages <= 1) {
        return; // Only one page => no pagination
    }

    // Previous Button
    let prevDisabled = currentPage == 1 ? 'disabled' : '';
    pagination.append(`
        <li class="page-item ${prevDisabled}">
            <a class="page-link" href="#" data-page="${currentPage - 1}" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
    `);

    // Numbered pages (simple approach of +/- 2 pages)
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);
    for (let i = startPage; i <= endPage; i++) {
        let active = (currentPage == i) ? 'active' : '';
        pagination.append(`
            <li class="page-item ${active}">
                <a class="page-link" href="#" data-page="${i}">${i}</a>
            </li>
        `);
    }

    // Next Button
    let nextDisabled = currentPage == totalPages ? 'disabled' : '';
    pagination.append(`
        <li class="page-item ${nextDisabled}">
            <a class="page-link" href="#" data-page="${currentPage + 1}" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    `);

    // On-click for pagination links
    $('.page-link').on('click', function(e) {
        e.preventDefault();
        let page = $(this).data('page');
        if (page >= 1 && page <= totalPages) {
            loadProducts(page);
            $('html, body').animate({
                scrollTop: $('#products-container').offset().top - 100
            }, 500);
        }
    });
}
