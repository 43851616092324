export function updatePhoneNumber() {
    fetch('/api/method/zoni_edu.controller.get_phone_number_from_ip', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        let phoneNumber = data.message || '+1-212-736-9000';
        $('.header_contact_phone_text').html(phoneNumber);
        $('.header_contact_phone_href').attr('href', 'tel:' + phoneNumber);
    })
    .catch(error => {
        console.error('Error fetching country based on IP:', error);
    });
}
